<template>
  <div class="tc-local_backup">
    <!-- 顶部功能 -->
    <div class="header-tool">
      <div class="del">
        <img src="../../../assets/images/retreatBtn.png" alt />
      </div>
      <div class="refresh" @click="refresh()">
        <img src="../../../assets/images/refresh.png" alt />
      </div>
    </div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="130px" size="mini">
        <el-form-item label="选择报告类型：">
          <el-input v-model="form.name" placeholder="自动备份_20200424084159"></el-input>
        </el-form-item>
        <el-form-item label="数据库名称：">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="备份路径：">
          <!-- <span>请先选择部门</span> -->
          <el-select v-model="form.region">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据库描述：">
          <el-input type="textarea" v-model="form.desc" :rows="5"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="confirm-btn">
      <img src="../../../assets/images/confirm.png" alt />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "0",
        desc: "",
        value1: "",
        date: []
      }
    };
  },
  methods: {
    onSubmit() {
      //console.log("submit!");
    }
  }
};
</script>
<style lang="less">
.tc-local_backup {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 120px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 120px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  .form {
    margin-top: 20px;
    margin-left: 50px;
    width: 500px;
    .el-input__inner {
      width: 200px;
      padding-right: 20px;
    }
  }
  .confirm-btn {
     margin-top: 20px;
     margin-left:300px ;
  }
}
</style>